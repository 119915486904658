// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_8ZQmo{padding:1rem}.grid_31MGS{grid-gap:2rem;display:grid;gap:2rem;grid-auto-flow:dense;grid-template-columns:auto auto}.section_grePG,.subsection_T7Rat{display:flex;flex-direction:column}.section_grePG{gap:1rem}.subsection_T7Rat{gap:.5rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_8ZQmo",
	"grid": "grid_31MGS",
	"section": "section_grePG",
	"subsection": "subsection_T7Rat"
};
module.exports = ___CSS_LOADER_EXPORT___;
